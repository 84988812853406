import { t } from 'bv-i18n';

const { showSuccessMessage } = window.ModalHelper;

export default () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get('show_success_message')) {
    queryParams.delete('show_success_message');

    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${queryParams.toString()}`,
    );

    showSuccessMessage({
      message: t('your_mobile_preferences_have_been_updated'),
      actions: [{ label: t('close'), primary: true, close: true }],
    });
  }
};
