import { connect } from 'react-redux';
import _, { compose, partial } from 'underscore';

import { onChange } from '../duck';

const mapDispatchToProps = (dispatch) => ({
  onChange: compose(dispatch, onChange),
});

export default partial(connect, _, mapDispatchToProps);
