import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const SubmitButton = ({ onClick }) => (
  <Button
    label={t('save_preferences')}
    type="submit"
    className="bvs-button-sticky"
    onClick={onClick}
  />
);

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SubmitButton;
