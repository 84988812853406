import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import OptionsList from './marketing_options/options_list';

const MarketingOptions = ({
  title, marketingSettings, onChange, footer,
}) => (
  <div className="marketing-options">
    <div className="marketing-options__card">
      {title && (
        <div className="marketing-options__title">
          {title}
        </div>
      )}
      <OptionsList marketingSettings={marketingSettings} onChange={onChange} />
    </div>

    {footer && (
      <div className="marketing-options__info" dangerouslySetInnerHTML={{ __html: sanitize(footer) }} />
    )}
  </div>
);

MarketingOptions.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.string,
  marketingSettings: PropTypes.shape,
  onChange: PropTypes.func,
};

MarketingOptions.defaultProps = {
  title: '',
  footer: '',
  marketingSettings: {},
  onChange: null,
};

export default MarketingOptions;
