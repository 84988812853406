import { t } from 'bv-i18n';
import { useFeature } from 'bv-hooks';
import connect from 'MainSettings/helpers/connect';
import AcceptAllPriceChangesToggle from 'AcceptAllPriceChangesToggle/components/toggle_redux';
import AcceptAllLineChangesToggle from 'AcceptAllLineChangesToggle/components/toggle_redux';
import withSection from 'MainSettings/hocs/with_section';
import { getLineAndPriceChanges } from 'MainSettings/selectors';

const Title = () => {
  const [followLineChanges, loaded] = useFeature('follow_line_changes');

  if (!loaded) return null;
  return (
    followLineChanges
      ? t('accept_all_price_changes_toggle.title_with_lines')
      : t('javascript.accept_price_changes_setting.settings_title')
  );
};

export default connect(getLineAndPriceChanges)(

  withSection({
    title: Title,
    className: 'accept-price-changes-wrapper',
  })(
    (props) => {
      const [followLineChanges, loaded] = useFeature('follow_line_changes');
      if (!loaded) return null;
      return followLineChanges ? (
        <div className="list-wrapper">
          <div className="row list">
            <AcceptAllPriceChangesToggle {...props} />
          </div>
          <div className="row list">
            <AcceptAllLineChangesToggle {...props} />
          </div>
        </div>
      ) : <AcceptAllPriceChangesToggle {...props} />;
    },
  ),
);
