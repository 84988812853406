import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';

import connect from 'MainSettings/helpers/connect';
import MarketingOptions from 'MainSettings/components/marketing_options';
import withSection from 'MainSettings/hocs/with_section';
import { getEntryFromState } from 'MainSettings/selectors';

const footerLink = bvVar('disablePushNotificationsLink');

const Marketing = (props) => (
  <MarketingOptions
    title={t('javascript.preferences.marketing.title')}
    footer={footerLink && t('javascript.preferences.marketing.how_to_disable_notifications_html', { footerLink })}
    {...props}
  />
);

const mapStateToProps = getEntryFromState('marketing', {
  extra: (entries) => ({
    marketingSettings: {
      ...entries.marketing,
      settings: entries.marketing.options.reduce((accum, option) => ({
        ...accum,
        [option]: entries[`marketing[${option}]`]?.value || false,
      }), {}),
    },
  }),
});

export default connect(mapStateToProps)(
  withSection({
    title: 'special_offers',
  })(Marketing),
);
