import PropTypes from 'prop-types';

import OptionItem from './option_item';

const OptionsList = ({ marketingSettings, onChange }) => (
  <ul className="marketing-options__options">
    {marketingSettings.options.map((option) => (
      <OptionItem
        key={option}
        defaultChecked={!!marketingSettings.settings[option]}
        option={option}
        onChange={onChange}
      />
    ))}
  </ul>
);

OptionsList.propTypes = {
  marketingSettings: PropTypes.shape,
  onChange: PropTypes.func,
};

OptionsList.defaultProps = {
  marketingSettings: {},
  onChange: null,
};

export default OptionsList;
