import { withModalWindow } from 'bv-hocs';

import MainSettings from 'MainSettings/components/app';
// import AcceptPriceChanges from 'MainSettings/components/accept_price_changes';
// import MarketingOptions from 'MainSettings/components/marketing_options';
import mainSettingsReducer from 'MainSettings/duck';

// Can be removed after safer_gambling_portal app will be migrated to frontend
// eslint-disable-next-line import/no-webpack-loader-syntax
require('expose-loader?SettingsSubmitApi!./features/main/api/submit_settings');

const { addReducers } = window.reduxState;

addReducers({
  mainSettings: mainSettingsReducer,
});

const MainSettingsModal = withModalWindow('preferences',
  { className: 'settings-modal' })(MainSettings);

export default MainSettingsModal;
