import { t } from 'bv-i18n';

import connect from 'MainSettings/helpers/connect';
import LabelledOnOff from 'MainSettings/components/shared/labelled_on_off';
import withSection from 'MainSettings/hocs/with_section';
import { getEntryFromState } from 'MainSettings/selectors';

const Quickbet = (props) => (
  <LabelledOnOff label={t('quickbet_title')} {...props} />
);

const mapStateToProps = getEntryFromState('quickbet', { as: 'checked' });

export default connect(mapStateToProps)(
  withSection({
    title: 'betslip_options',
  })(Quickbet),
);
