import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'underscore';

import { Spinner, RenderInModal } from 'bv-components';
import { fetchSettings, submitSettings, loadComponents } from 'MainSettings/duck';

import { getComponentsList } from 'MainSettings/selectors';
import componentsList from 'MainSettings/helpers/components_list';
import handleSuccessMessage from 'MainSettings/handlers/handle_success_message';
import SubmitButton from 'MainSettings/components/submit_button';

const SettingsApp = () => {
  const dispatch = useDispatch();

  const doFetchSettings = (callback) => dispatch(fetchSettings(callback));
  const doSubmitSettings = compose(dispatch, submitSettings);
  const doLoadComponents = compose(dispatch, loadComponents, componentsList);

  const fetched = useSelector(({ mainSettings }) => mainSettings.fetched);
  const submitting = useSelector(({ mainSettings }) => mainSettings.submitting);
  const componentsAreLoading = useSelector(({ mainSettings }) => mainSettings.componentsAreLoading);
  const components = useSelector(getComponentsList);

  const showSpinner = !fetched || submitting || componentsAreLoading;

  useEffect(() => {
    handleSuccessMessage();
    doFetchSettings(doLoadComponents);
  }, []);

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <form className="settings" onSubmit={doSubmitSettings}>
      {
        components.map(({ name }) => {
          const Component = componentsList()
            .find((component) => component.name === name).component;

          return <Component />;
        })
      }
      <RenderInModal className="modal_sticky_button_wrapper bvs-button-sticky-wrapper">
        <SubmitButton onClick={doSubmitSettings} />
      </RenderInModal>
    </form>
  );
};

export default SettingsApp;
