import { Select } from 'bv-components';
import { t } from 'bv-i18n';

import connect from 'MainSettings/helpers/connect';
import withSection from 'MainSettings/hocs/with_section';
import { getEntryFromState } from 'MainSettings/selectors';

const OddsDisplay = (props) => (
  <div className="settings__flex-row">
    <div>{t('odds_display')}</div>
    <div>
      <Select {...props} />
    </div>
  </div>
);

const mapStateToProps = getEntryFromState('oddsDisplay');

export default connect(mapStateToProps)(
  withSection({
    title: 'odds_display',
  })(OddsDisplay),
);
