import { v as bvVar } from 'bv';
import { loggedIn } from 'bv-helpers/session';
import { hasSportsbook } from 'global-config';

import OddsDisplay from '../components/sections/odds_display';
import Quickbet from '../components/sections/quickbet';
import TwoFactorAuthStatus from '../components/sections/two_factor_auth_status';
import AcceptPriceChanges from '../components/sections/accept_price_changes';
import Marketing from '../components/sections/marketing';
import SendSpecialOffers from '../components/sections/send_special_offers';

const loggedOutComponentsList = [
  { name: 'oddsDisplay', position: 0, component: OddsDisplay },
  { name: 'quickbet', position: 1, component: Quickbet },
];

const loggedInComponentsList = [
  {
    name: 'twoFactorAuthStatus',
    position: 1,
    component: TwoFactorAuthStatus,
  },
  {
    name: 'acceptPriceChanges',
    position: 4,
    component: AcceptPriceChanges,
  },
  {
    name: 'quickbet',
    position: 5,
    component: Quickbet,
  },
  {
    name: 'oddsDisplay',
    position: 6,
    component: OddsDisplay,
  },
  {
    name: bvVar('isGdprBrand') ? 'marketing' : 'sendSpecialOffers',
    position: 7,
    component: bvVar('isGdprBrand') ? Marketing : SendSpecialOffers,
  },
].filter(Boolean);

const noSportsbookList = [
  {
    name: 'twoFactorAuthStatus',
    position: 1,
    component: TwoFactorAuthStatus,
  },
  {
    name: bvVar('isGdprBrand') ? 'marketing' : 'sendSpecialOffers',
    position: 4,
    component: bvVar('isGdprBrand') ? Marketing : SendSpecialOffers,
  },
  {
    name: 'oddsDisplay',
    position: 5,
    component: OddsDisplay,
  },
];

export default () => {
  if (hasSportsbook) {
    return loggedIn() ? loggedInComponentsList : loggedOutComponentsList;
  }

  return loggedIn() && noSportsbookList;
};
