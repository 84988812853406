import PropTypes from 'prop-types';
import Section from '../components/shared/section';

export default (sectionProps) => {
  const withSection = (WrappedComponent) => {
    const Component = ({ valid, ...rest }) => valid && (
      <Section {...sectionProps}>
        <WrappedComponent {...rest} />
      </Section>
    );

    Component.propTypes = {
      valid: PropTypes.bool.isRequired,
    };

    return Component;
  };
  return withSection;
};
