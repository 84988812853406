import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { Icon } from 'bv-components';

/* Maps backend marketing keys to icon/translation keys */
const MARKETING_OPTION_KEYS = {
  sendSocialMedia: 'social_media',
  sendSMS: 'sms',
  sendEmail: 'email',
  sendCustomerInbox: 'inbox',
  phone: 'phone',
};

const OptionItem = ({ option, defaultChecked, onChange }) => {
  const id = `${option}_checkbox`;
  const checkboxName = `marketing[${option}]`;

  return (
    <li>
      <input type="checkbox" id={id} name={checkboxName} defaultChecked={defaultChecked} onChange={onChange} value="true" />
      <label htmlFor={id}>
        <div className="option__icon">
          <Icon id={MARKETING_OPTION_KEYS[option]} active={defaultChecked} type="account-settings-offers-icon" />
        </div>
        <div className="option__name">
          {t(`javascript.registration_path.marketing.${MARKETING_OPTION_KEYS[option]}`)}
        </div>
      </label>
    </li>
  );
};

OptionItem.propTypes = {
  option: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

OptionItem.defaultProps = {
  onChange: null,
};

export default OptionItem;
