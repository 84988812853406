import PropTypes from 'prop-types';
import { OnOff } from 'bv-components';

const LabelledOnOff = ({ label, ...rest }) => (
  <div className="flex flex--centered">
    <div className="flex--grow">{label}</div>
    <OnOff className="small" {...rest} />
  </div>
);

LabelledOnOff.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LabelledOnOff;
