import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Section = ({ className, title, children }) => (
  <div className={`settings-section ${className}`}>
    <div className="bvs-header settings-section__title">
      { typeof title === 'function' ? title() : t(title) }
    </div>
    <div className="bvs-card settings-section__card">
      {children}
    </div>
  </div>
);

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  className: '',
};

export default Section;
